
/*---公用基础设置---*/
html {
    background: transparent;
    min-height: 100%;
    width: 100vw;
    left: 0;
    top: 0;
}
@font-face {
    font-family: fontN1;
    src: url(../font/font_N_1.TTF);
}

/*css-reset*/
html,
body,
div,
ul,
ol,
li,
dl,
dt,
dd,
p,
span,
em,
strong,
b,
i,
h1,
h2,
h3,
h4,
form,
iframe,
table,
thead,
tbody,
input,
button,
video,
textarea,
select,
option,
img,
a,
xmp,
pre,
code,
h1,
h2,
h3,
h4,
canvas {
    margin: 0;
    padding: 0;
    word-break: break-all;
    word-wrap: break-word;
    outline: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-family: "fontN1";
}
audio,
video,
ul,
ol,
dl,
dt,
dd,
p,
img {
    display: block;
    border: 0;
}
a {
    text-decoration: none;
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/ /*早期浏览器*/
    user-select: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:hover {
    text-decoration: none;
}
li {
    display: block;
    list-style: none;
}
textarea {
    resize: none;
    outline: none;
}
input {
    outline: none;
}
input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
::-webkit-scrollbar {
    width: 0.06rem;
    height: 0.06rem;
    background-color: #eee;
    border-radius: 0.1rem;
}
::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 0.1rem;
}
::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 0.1rem;
}

input[disabled] {
    background: rgba(184, 184, 184, 0.5);
    color: #9a9a9a;
}
/*---顶级样式名称---*/
/*隐藏*/
.hide {
    display: none !important;
}
/*显示*/
.show {
    display: block !important;
}
/*隐藏*/
.hidden {
    visibility: hidden !important;
}
/*字体撑开*/
.null {
    display: block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    text-indent: -999px;
}
.icon {
    background-repeat: no-repeat;
    background-size: 100% 100%;
}